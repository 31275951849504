import React, { useState } from "react"
import { getLoginData } from '../../redux/reducer/loginReducer';
import { useDispatch, useSelector } from "react-redux";
import AppLoader from "../AppLoader";
import ConfermationPopup from "../ConfermationPopup";
import { toast } from "react-toastify";
import { Actions } from "../../redux/action";
import { stopSocketConnect } from "../../socket/SocketConnection";

const Header = () => {
    const userData = useSelector(getLoginData)
    const dispatch = useDispatch()
    const [isLoading, setLoading] = useState(false);
    const [onChangePasswordPopup, setOnChangePasswordPopup] = useState(false);

    // const logout = () => {
    //     setLoading(true)
    //     localStorage.clear()
    //     stopSocketConnect(userData?.socketId)
    //     setLoading(false)
    //     window.location.href = '/'
    // }
    const logout = async() => {
        const callback = async (response) => {
            setLoading(false)
            if (response?.response_code === 0) {
                // await logoutUser()
                stopSocketConnect(userData?.socketId)
                // toast.success(response?.response_message)
                window.location.href = '/'
            } else {
                toast.error(response?.response_message)
            }
        }
        let params = {
            username: userData?.email,
        }
        setLoading(true)
        dispatch(Actions.logOutUser(params, callback))
    }
 
    const onChangePassword = () => {
        setOnChangePasswordPopup(true)
    }
    const onCloseModel = () => {
        setOnChangePasswordPopup(false)
    };
    const onSubmit = (formValues) => {
        setLoading(true)
        const callback = (response) => {
            setLoading(false)
            if (response?.response_code === 0) {
                onCloseModel()
                toast.success(response?.response_message)
            } else {
                toast.error(response?.response_message)
            }
        }
        let params = {
            email: userData?.email,
            old_password: formValues?.oldPassword,
            new_password: formValues?.newPassword
        }
        dispatch(Actions.changePasswordUser(params, callback))
    }
    return (
        <React.Fragment>
            {isLoading && <AppLoader />}
            <div className="top_header top_header_mid top_header_cover">
                <div className="logo_menu">
                    <div className="side_menu_mob">
                        {/* <div className="d-flex align-items-center">
                            <div className="search_icon">
                                <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth="1.5" stroke="currentColor" width="22" className="ps-1">
                                    <path strokeLinecap="round" strokeLinejoin="round" d="M21 21l-5.197-5.197m0 0A7.5 7.5 0 105.196 5.196a7.5 7.5 0 0010.607 10.607z" />
                                </svg>
                            </div>
                            <input className="search_input" type="search" placeholder="Search" aria-label="Search" />
                        </div> */}
                        <button className="toggle_btn btn" type="button" data-bs-toggle="offcanvas"
                            data-bs-target="#offcanvasExample" aria-controls="offcanvasExample">
                            <svg xmlns="http://www.w3.org/2000/svg" width="27" height="27" fill="currentColor"
                                className="bi bi-arrow-right arrow_mob" viewBox="0 0 16 16">
                                <path fillRule="evenodd"
                                    d="M1 8a.5.5 0 0 1 .5-.5h11.793l-3.147-3.146a.5.5 0 0 1 .708-.708l4 4a.5.5 0 0 1 0 .708l-4 4a.5.5 0 0 1-.708-.708L13.293 8.5H1.5A.5.5 0 0 1 1 8z" />
                            </svg>
                        </button>
                    </div>

                    <div className="dropdown">
                        <button className="btn dropdown-toggle profile_name" type="button" id="dropdownMenu2" data-bs-toggle="dropdown" aria-expanded="false">
                            <div src="" alt="profile" className="profile_logo"><span>{userData?.first_name?.charAt(0)}</span></div>
                            <p className="profile_name m-0">{userData?.first_name} {userData?.last_name}</p>
                        </button>
                        <ul className="dropdown-menu" aria-labelledby="dropdownMenu2">
                            <li><button onClick={onChangePassword} className="dropdown-item" type="button">Change Password</button></li>
                            <li><button onClick={logout} className="dropdown-item" type="button">Logout</button></li>
                        </ul>
                    </div>
                </div>
                {onChangePasswordPopup && <ConfermationPopup
                    onClose={() => onCloseModel()}
                    onSuccess={(formValues) => onSubmit(formValues)} />}
            </div>
        </React.Fragment>
    )
}

export default Header