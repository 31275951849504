import React, { useState } from 'react';
import loginImage from '../asset/img/login_img.svg';
import { isValidEmail } from "../utils/Validation"
import { useDispatch } from 'react-redux';
import { Actions } from '../redux/action';
import { toast } from 'react-toastify';
import { useNavigate } from "react-router-dom"
import AppLoader from '../component/AppLoader';

const ForgetPassword = () => {
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const [isLoading, setLoading] = useState(false);
    const [isShowOpt, setShowOpt] = useState(false);
    const [formData, setFormData] = useState({
        email: '',
        otp1: '',
        otp2: '',
        otp3: '',
        otp4: '',
        otp5: '',
        otp6: '',
    });
    const otpRef1 = React.createRef();
    const otpRef2 = React.createRef();
    const otpRef3 = React.createRef();
    const otpRef4 = React.createRef();
    const otpRef5 = React.createRef();
    const otpRef6 = React.createRef();

    const [errors, setErrors] = useState({
        email: '',
        otp: '',
    });
    const nextFocusOTP = (name) => {
        switch (name) {
            case "otp1":
                otpRef2.current.focus();
                break;
            case "otp2":
                otpRef3.current.focus();
                break;
            case "otp3":
                otpRef4.current.focus();
                break;
            case "otp4":
                otpRef5.current.focus();
                break;
            case "otp5":
                otpRef6.current.focus();
                break;
            default:
                break;
        }
    }
    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData({
            ...formData,
            [name]: value,
        });
        if (value.length > 1) {
            e.target.value = value.slice(0, 1);
        }
        nextFocusOTP(name)
    };

    const handleSubmit = (e, isOtpResend) => {
        e.preventDefault();
        const callback = (response) => {
            setLoading(false)
            if (response?.response_code !== 0) {
                toast.error(response?.response_message)
            } else {
                setShowOpt(true)
                toast.success(response?.response_message)
                if (isOtpResend) {
                    setFormData((state) => ({
                        email: state.email,
                        otp1: '',
                        otp2: '',
                        otp3: '',
                        otp4: '',
                        otp5: '',
                        otp6: '',
                    }));
                } else if (isShowOpt && isOtpResend === false) {
                    dispatch(Actions.loginCheckSuccess({ email: formData?.email }))
                    navigate("/resetPassword")
                } else {
                    return
                }

            }
        }
        if (!isValidEmail(formData?.email)) {
            setErrors({
                ...errors,
                email: 'Please enter a valid email address.',
            });
        }
        else if (
            (formData.otp1.length !== 1 && isShowOpt && !isOtpResend) ||
            (formData.otp2.length !== 1 && isShowOpt && !isOtpResend) ||
            (formData.otp3.length !== 1 && isShowOpt && !isOtpResend) ||
            (formData.otp4.length !== 1 && isShowOpt && !isOtpResend) ||
            (formData.otp5.length !== 1 && isShowOpt && !isOtpResend) ||
            (formData.otp6.length !== 1 && isShowOpt && !isOtpResend)
        ) {
            setErrors({
                ...errors,
                otp: 'Please enter a valid OTP.',
            });
        }
        else {
            setErrors({
                email: '',
                otp: '',
            });
            setLoading(true)
            if (isShowOpt && !isOtpResend) {
                dispatch(Actions.verifyOtpUser({ email: formData?.email, email_otp: `${formData?.otp1 + formData?.otp2 + formData?.otp3 + formData.otp4 + formData.otp5 + formData.otp6}` }, callback))
            } else {
                dispatch(Actions.forgetPasswordUser({ email: formData?.email }, callback))
            }
        }

    };

    return (
        <React.Fragment>
            {isLoading && <AppLoader />}
            <div className="login_main_screen">
                <div className="card login_card">
                    <div className="row">
                        <div className="col-md-6 d-md-flex align-items-center d-none">
                            <img src={loginImage} className="img-fluid p-4" alt="Login" />
                        </div>
                        <div className="col-md-6 d-flex align-items-center">
                            {/* Forgot start */}
                            <div className="px-md-5 p-4">
                                <p className="fs-24 f-sbold">FORGOT PASSWORD</p>
                                {/* <p className="fs-14 f-reg">
                                    Please enter your email address you'd like your password reset information sent to
                                </p> */}
                                <div className="email">
                                    <label className="form-label fs-16 f-sbold mb-3">Email</label>
                                    <input
                                        type="email"
                                        className="form-control select_options aisle_name"
                                        placeholder="Enter your email here"
                                        onChange={(e) => handleChange(e)}
                                        name="email"
                                        value={formData?.email}
                                    />
                                    {errors?.email && <p className="text-danger">{errors?.email}</p>}
                                </div>
                                {isShowOpt && <div className="otp mt-3">
                                    <label className="form-label fs-16 f-sbold mb-3">OTP</label>
                                    <div className="d-flex gap-3">
                                        <input
                                            type="text"
                                            className="form-control select_options aisle_name"
                                            name="otp1"
                                            value={formData?.otp1}
                                            onInput={(e) => handleChange(e)} // Use onInput instead of onChange
                                            maxLength="1" // Restrict input to one character
                                            ref={otpRef1}
                                        />
                                        <input
                                            type="text"
                                            className="form-control select_options aisle_name"
                                            name="otp2"
                                            value={formData?.otp2}
                                            onInput={(e) => handleChange(e)}
                                            maxLength="1"
                                            ref={otpRef2}
                                        />
                                        <input
                                            type="text"
                                            className="form-control select_options aisle_name"
                                            name="otp3"
                                            value={formData?.otp3}
                                            onInput={(e) => handleChange(e)}
                                            maxLength="1"
                                            ref={otpRef3}
                                        />
                                        <input
                                            type="text"
                                            className="form-control select_options aisle_name"
                                            name="otp4"
                                            value={formData?.otp4}
                                            onInput={(e) => handleChange(e)}
                                            maxLength="1"
                                            ref={otpRef4}
                                        />
                                        <input
                                            type="text"
                                            className="form-control select_options aisle_name"
                                            name="otp5"
                                            value={formData?.otp5}
                                            onInput={(e) => handleChange(e)}
                                            maxLength="1"
                                            ref={otpRef5}
                                        />
                                        <input
                                            type="text"
                                            className="form-control select_options aisle_name"
                                            name="otp6"
                                            value={formData?.otp6}
                                            onInput={(e) => handleChange(e)}
                                            maxLength="1"
                                            ref={otpRef6}
                                        />
                                    </div>
                                    {errors?.otp && <p className="text-danger">{errors?.otp}</p>}
                                </div>}
                                <button className="btn login_btn mt-4" type="button" onClick={(e) => handleSubmit(e, false)}>
                                    {!isShowOpt ? "Send OTP" : "Submit"}
                                </button>
                                {!isShowOpt && <div className="password mt-2">
                                    <span className="mt-2 ml-2 pointer-hover" style={{ "margin-left": "30Px" }} onClick={() => navigate('/')}>Login</span>
                                </div>}
                                &nbsp;
                                {isShowOpt && <button className="btn login_btn mt-4" type="button" onClick={(e) => handleSubmit(e, true)}>
                                    Resend OTP
                                </button>}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </React.Fragment>

    );
};

export default ForgetPassword;
