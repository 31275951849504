import { doAction, doResponseAction } from ".";

const LOGIN_USER = 'LOGIN_USER';
const LOGIN_USER_SUCCESS = 'LOGIN_USER_SUCCESS';
const LOGIN_USER_FAILURE = 'LOGIN_USER_FAILURE';

const CLIENT_CREDENTIALS = 'CLIENT_CREDENTIALS';
const CLIENT_CREDENTIALS_SUCCESS = 'CLIENT_CREDENTIALS_SUCCESS';
const CLIENT_CREDENTIALS_FAILURE = 'CLIENT_CREDENTIALS_FAILURE';

const LOGOUT_USER = 'LOGOUT_USER';
const LOGOUT_USER_SUCCESS = 'LOGOUT_USER_SUCCESS';
const LOGOUT_USER_FAILURE = 'LOGOUT_USER_FAILURE';

const LOGIN_CHECK_DATA = 'LOGIN_CHECK_DATA';

const FORGET_PASSWORD_USER = 'FORGET_PASSWORD_USER';
const FORGET_PASSWORD_USER_SUCCESS = 'FORGET_PASSWORD_USER_SUCCESS';
const FORGET_PASSWORD_USER_FAILURE = 'FORGET_PASSWORD_USER_FAILURE';

const RESET_PASSWORD_USER = 'RESET_PASSWORD_USER';
const RESET_PASSWORD_USER_SUCCESS = 'RESET_PASSWORD_USER_SUCCESS';
const RESET_PASSWORD_USER_FAILURE = 'RESET_PASSWORD_USER_FAILURE';

const VERIFY_OTP_USER = 'VERIFY_OTP_USER';
const VERIFY_OTP_USER_SUCCESS = 'VERIFY_OTP_USER_SUCCESS';
const VERIFY_OTP_USER_FAILURE = 'VERIFY_OTP_USER_FAILURE';

const CHANGE_PASSWORD_USER = 'CHANGE_PASSWORD_USER';
const CHANGE_PASSWORD_USER_SUCCESS = 'CHANGE_PASSWORD_USER_SUCCESS';
const CHANGE_PASSWORD_USER_FAILURE = 'CHANGE_PASSWORD_USER_FAILURE';

const CAPTCHA = 'CAPTCHA';
const CAPTCHA_SUCCESS = 'CAPTCHA_SUCCESS';
const CAPTCHA_FAILURE = 'CAPTCHA_FAILURE';

const REFRESH_TOKEN = 'REFRESH_TOKEN';
const REFRESH_TOKEN_SUCCESS = 'REFRESH_TOKEN_SUCCESS';
const REFRESH_TOKEN_FAILURE = 'REFRESH_TOKEN_FAILURE';

export {
    LOGIN_USER,
    LOGIN_USER_FAILURE,
    LOGIN_USER_SUCCESS,
    CLIENT_CREDENTIALS,
    CLIENT_CREDENTIALS_FAILURE,
    CLIENT_CREDENTIALS_SUCCESS,
    LOGOUT_USER,
    LOGOUT_USER_FAILURE,
    LOGOUT_USER_SUCCESS,
    FORGET_PASSWORD_USER,
    FORGET_PASSWORD_USER_FAILURE,
    FORGET_PASSWORD_USER_SUCCESS,
    RESET_PASSWORD_USER,
    RESET_PASSWORD_USER_FAILURE,
    RESET_PASSWORD_USER_SUCCESS,
    VERIFY_OTP_USER,
    VERIFY_OTP_USER_FAILURE,
    VERIFY_OTP_USER_SUCCESS,
    CHANGE_PASSWORD_USER,
    CHANGE_PASSWORD_USER_FAILURE,
    CHANGE_PASSWORD_USER_SUCCESS,
    LOGIN_CHECK_DATA,
    CAPTCHA,
    CAPTCHA_FAILURE,
    CAPTCHA_SUCCESS,
    REFRESH_TOKEN,
    REFRESH_TOKEN_FAILURE,
    REFRESH_TOKEN_SUCCESS,
}

// LOGIN
export const loginUser = (params, callback) => doAction(LOGIN_USER, params, callback);
export const loginUserSuccess = (data) => doResponseAction(LOGIN_USER_SUCCESS, data);
export const loginUserFailure = (data) => doResponseAction(LOGIN_USER_FAILURE, data);

// CLIENT_CREDENTIALS
export const clientCredentials = (params, callback) => doAction(CLIENT_CREDENTIALS, params, callback);
export const clientCredentialsSuccess = (data) => doResponseAction(CLIENT_CREDENTIALS_SUCCESS, data);
export const clientCredentialsFailure = (data) => doResponseAction(CLIENT_CREDENTIALS_FAILURE, data);

// LOGOUT
export const logOutUser = (params, callback) => doAction(LOGOUT_USER, params, callback);
export const logOutUserSuccess = (data) => doResponseAction(LOGOUT_USER_SUCCESS, data);
export const logOutUserFailure = (data) => doResponseAction(LOGOUT_USER_FAILURE, data);

// FORGET_PASSWORD
export const forgetPasswordUser = (params, callback) => doAction(FORGET_PASSWORD_USER, params, callback);
export const forgetPasswordUserSuccess = (data) => doResponseAction(FORGET_PASSWORD_USER_SUCCESS, data);
export const forgetPasswordUserFailure = (data) => doResponseAction(FORGET_PASSWORD_USER_FAILURE, data);

// RESET_PASSWORD
export const resetPasswordUser = (params, callback) => doAction(RESET_PASSWORD_USER, params, callback);
export const resetPasswordUserSuccess = (data) => doResponseAction(RESET_PASSWORD_USER_SUCCESS, data);
export const resetPasswordUserFailure = (data) => doResponseAction(RESET_PASSWORD_USER_FAILURE, data);

// VERIFY_PASSWORD
export const verifyOtpUser = (params, callback) => doAction(VERIFY_OTP_USER, params, callback);
export const verifyOtpUserSuccess = (data) => doResponseAction(VERIFY_OTP_USER_SUCCESS, data);
export const verifyOtpUserFailure = (data) => doResponseAction(VERIFY_OTP_USER_FAILURE, data);

// CHANGE_PASSWORD
export const changePasswordUser = (params, callback) => doAction(CHANGE_PASSWORD_USER, params, callback);
export const changePasswordUserSuccess = (data) => doResponseAction(CHANGE_PASSWORD_USER_SUCCESS, data);
export const changePasswordUserFailure = (data) => doResponseAction(CHANGE_PASSWORD_USER_FAILURE, data);

export const loginCheckSuccess = (data) => doResponseAction(LOGIN_CHECK_DATA, data);

// CAPTCHA
export const captcha = (params, callback) => doAction(CAPTCHA, params, callback);
export const captchaSuccess = (data) => doResponseAction(CAPTCHA_SUCCESS, data);
export const captchaFailure = (data) => doResponseAction(CAPTCHA_FAILURE, data);

// REFRESH_TOKEN
export const refreshToken = (params, callback) => doAction(REFRESH_TOKEN, params, callback);
export const refreshTokenSuccess = (data) => doResponseAction(REFRESH_TOKEN_SUCCESS, data);
export const refreshTokenFailure = (data) => doResponseAction(REFRESH_TOKEN_FAILURE, data);
