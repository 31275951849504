import React, { useState } from 'react';
import loginImage from '../asset/img/login_img.svg';
import { useDispatch, useSelector } from 'react-redux';
import { Actions } from '../redux/action';
import { toast } from 'react-toastify';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEye, faEyeSlash } from '@fortawesome/free-solid-svg-icons';
import { useNavigate } from 'react-router-dom';
import { userEmailId } from '../redux/reducer/loginReducer';
import AppLoader from '../component/AppLoader';
import PasswordCheckPopover from '../component/PasswordCheckPopover';

const ResetPassword = () => {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const userEmail = useSelector(userEmailId)
    const [isLoading, setLoading] = useState(false);
    const [showPassword1, setShowPassword1] = useState(false);
    const [showPassword2, setShowPassword2] = useState(false);
    const [showPopoverForNewPass, setShowPopoverForNewPass] = useState(false)
    const [showPopoverForConfirmPass, setShowPopoverForConfirmPass] = useState(false)
    const [formValues, setFormValues] = useState({
        newPassword: "",
        confirmPassword: "",
    });
    const [formErrors, setFormErrors] = useState({
        newPassword: "",
        confirmPassword: "",
    });
    const handleInputChange = (event) => {
        const { name, value } = event.target;
        setFormValues({
            ...formValues,
            [name]: value,
        });
    };

    const togglePasswordVisibility1 = () => {
        setShowPassword1(!showPassword1);
    };
    const togglePasswordVisibility2 = () => {
        setShowPassword2(!showPassword2);
    };
    const validatePassword = (password) => {
        // Password validation rules can be added here
        return password.length >= 8;
    };
    const validateForm = () => {
        let errors = {};

        if (!formValues?.newPassword) {
            errors.newPassword = "New Password is required";
        }
        else if (!validatePassword(formValues?.newPassword)) {
            errors.newPassword = "Strong Password is required";
        }
        else if (!formValues?.confirmPassword) {
            errors.confirmPassword = "Confirm Password is required";
        }
        else if (!validatePassword(formValues?.confirmPassword)) {
            errors.confirmPassword = "Strong Password is required";
        }
        else if (formValues?.newPassword !== formValues?.confirmPassword) {
            errors.confirmPassword = "";
            toast.warning("Passwords does not match")
        }

        setFormErrors(errors);

        return Object.keys(errors).length === 0;
    };
    const handleSubmit = () => {
        const callback = (response) => {
            setLoading(false)
            if (response?.response_code !== 0) {
                toast.error(response?.response_message)
            } else {
                toast.success(response?.response_message)
                navigate("/")
            }
        }
        setLoading(true)
        dispatch(Actions.resetPasswordUser({ email: userEmail?.email, new_password: formValues?.newPassword }, callback))
    };

    return (
        <React.Fragment>
            {isLoading && <AppLoader />}
            <div className="login_main_screen">
                <div className="card login_card">
                    <div className="row">
                        <div className="col-md-6 d-md-flex align-items-center d-none">
                            <img src={loginImage} className="img-fluid p-4" alt="Login" />
                        </div>
                        <div className="col-md-6 d-flex align-items-center">
                            <div className="p-lg-0 px-md-5 p-4">
                                <p className="fs-24 f-sbold">RESET PASSWORD</p>
                                {/* <p className="fs-14 f-reg">
                                    Please enter your new password
                                </p> */}
                                <div className="col-12">
                                    <label htmlFor="newPassword" className="form-label">
                                        New Password
                                    </label>
                                    <div className="input-group">
                                        {showPopoverForNewPass && <PasswordCheckPopover value={formValues?.newPassword} />}
                                        <input
                                            type={showPassword1 ? "text" : "password"}
                                            className="form-control"
                                            id="newPassword"
                                            name="newPassword"
                                            placeholder="Enter new password"
                                            value={formValues?.newPassword}
                                            onChange={handleInputChange}
                                            onFocus={() => setShowPopoverForNewPass(true)}
                                            onBlur={() => setShowPopoverForNewPass(false)}
                                            autoComplete="off"
                                        />
                                        <div onClick={togglePasswordVisibility1} className="input-group-append">
                                            <span className="input-group-text" style={{ padding: "11px" }}> 
                                                <FontAwesomeIcon
                                                    icon={showPassword1 ? faEye : faEyeSlash}
                                                />
                                            </span>
                                        </div>
                                    </div>
                                    <div className="text-danger">{formErrors?.newPassword}</div>
                                </div>
                                <div className="col-12 mt-4">
                                    <label htmlFor="confirmPassword" className="form-label">
                                        Confirm Password
                                    </label>
                                    <div className="input-group">
                                        {showPopoverForConfirmPass && <PasswordCheckPopover value={formValues?.confirmPassword} />}
                                        <input
                                            type={showPassword2 ? "text" : "password"}
                                            className="form-control"
                                            id="confirmPassword"
                                            name="confirmPassword"
                                            placeholder="Enter confirm password"
                                            value={formValues?.confirmPassword}
                                            onChange={handleInputChange}
                                            onFocus={() => setShowPopoverForConfirmPass(true)}
                                            onBlur={() => setShowPopoverForConfirmPass(false)}
                                            autoComplete="off"
                                        />
                                        <div onClick={togglePasswordVisibility2} className="input-group-append">
                                            <span className="input-group-text" style={{ padding: "11px" }}> 
                                                <FontAwesomeIcon
                                                    icon={showPassword2 ? faEye : faEyeSlash}
                                                />
                                            </span>
                                        </div>
                                    </div>
                                    <div className="text-danger">{formErrors?.confirmPassword}</div>
                                </div>
                                <button className="btn login_btn mt-3" type="button"
                                    onClick={() => {
                                        if (validateForm()) {
                                            handleSubmit();
                                        }
                                    }}>
                                    Submit
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </React.Fragment>
    );
};

export default ResetPassword;
