import React, { useCallback, useEffect, useState } from "react"
import { useDispatch, useSelector } from "react-redux";
import { Actions } from '../redux/action';
import { notificationList, totalItemCount } from '../redux/reducer/notificationReducer';
import { toast } from "react-toastify";
import { PaginationComponent } from "../component/Pagination";
import { DeclinePopup } from "../component/DeclinePopup";
import MESSAGE from "../constant/String";
import { getLoginData } from "../redux/reducer/loginReducer";
import StoreTypeAhead from "../component/StoreTypeAhead";
import KisokTypeAhead from "../component/KioskTypeAhead";
import AppLoader from "../component/AppLoader";



const Notification = () => {
    const dispatch = useDispatch()
    const notificationListData = useSelector(notificationList)
    const totalCount = useSelector(totalItemCount)
    const userData = useSelector(getLoginData)
    const [isLoading, setLoading] = useState(false)
    const [pageNumber, setPageNumber] = useState(1);
    const [isOpenDeclineModal, setOpenDeclineModal] = useState(false);
    const [selectedStoreOption, setSelectedStoreOption] = useState([]);
    const [selectedKioskOption, setSelectedkioskOption] = useState([]);
    const [itemObj, setItemObj] = useState('')

    const getNotificationList = useCallback(() => {
        const callback = () => {
            setLoading(false)
        }
        let params = {
            page: pageNumber,
            page_size: MESSAGE?.ITEM_COUNT_PER_PAGE,
            store_id: selectedStoreOption?.value,
            kiosk_id: selectedKioskOption?.value
        }
        setLoading(true)
        dispatch(Actions.getNotification(params, callback))
    }, [dispatch, pageNumber, selectedStoreOption?.value, selectedKioskOption?.value])

    useEffect(() => {
        getNotificationList()
        dispatch(Actions.activeStore())
    }, [dispatch, getNotificationList])

    const activeNotification = () => {
        setLoading(true)
        const callback = (response) => {
            setLoading(false)
            if (response?.response_code === 0) {
                onCloseModel()
                handleBackButtonClick();
                getNotificationList();
                toast.success(response?.response_message)
            } else {
                toast.error(response?.response_message)
            }
        }
        let params = {
            "notification_id": itemObj.id,
            "is_active": itemObj?.is_active ? "False" : "True"
        }
        dispatch(Actions.deleteNotification(params, callback))
    }
    const onCloseModel = () => {
        setItemObj('');
        setOpenDeclineModal(false)
    };
    const onChangeHandle = (pageNumber) => {
        setPageNumber(pageNumber)
    }
    const onStoreChange = (selected) => {
        setPageNumber(1)
        setSelectedStoreOption(selected)
        setSelectedkioskOption([])
    };
    const onKioskChange = (selected) => {
        setSelectedkioskOption(selected?.value ? selected : [])
    };
    return (
        <React.Fragment>
            {isLoading && <AppLoader />}
            <div className="vision_dashboard">
                <div className="vision_dashboard">
                    <div className="vision_dashboard_content">
                        <div className="right_side right_side_mid right_side_cover ">
                            <div className="body_content">
                                {/* <!-- Navigation Button start --> */}
                                <div className="body_header">
                                    <p className="m-0 title">Notification</p>
                                    <div className="d-flex gap-2">
                                        <p className="m-0 title_active pointer-hover" >Notification list</p>
                                    </div>
                                </div>
                                {/* <!-- add button start --> */}
                                <div className="d-md-flex justify-content-between my-4">
                                    <div className="d-md-flex gap-4">
                                        {userData?.role_id !== 3 && <StoreTypeAhead
                                            className="dropdown_wrap"
                                            onChange={onStoreChange}
                                            id="StoreTypeAhead"
                                            style={{ margin: '10px' }}
                                            placeholder="Select the store"
                                            isMulti={false}
                                            defaultValue={selectedStoreOption}
                                            disabled
                                        />}
                                        {(selectedStoreOption?.value || userData?.role_id === 3) && <KisokTypeAhead
                                            className="dropdown_wrap"
                                            id="kioskTypeAhead"
                                            style={{ margin: '10px' }}
                                            placeholder="Select the kiosk"
                                            isMulti={false}
                                            onChange={onKioskChange}
                                            disabled
                                            selectedStoreOption={selectedStoreOption}
                                            defaultValue={selectedKioskOption}
                                            value={(selectedStoreOption?.value || selectedKioskOption) ? selectedKioskOption : []}
                                        />}
                                    </div>
                                </div>
                                {/* <!-- add button end --> */}
                                {/* <!-- table start --> */}
                                <div className="table_bg">
                                    <div className="table_body">
                                        <table className="table">
                                            <thead>
                                                <tr>
                                                    <th scope="col">S.No</th>
                                                    <th scope="col">Message</th>
                                                    {/* <th scope="col">Start Time & End Time</th> */}
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {Array.isArray(notificationListData) && notificationListData?.map((obj, index) => (
                                                    <tr key={obj?.id}>
                                                        <td>{((pageNumber - 1) * MESSAGE?.ITEM_COUNT_PER_PAGE) + (index + 1)}</td>
                                                        <td>{obj?.message}</td>
                                                        {/* <td>{moment(obj?.start_time).format("hh:mm:ss A")} - {moment(obj?.end_time).format("hh:mm:ss A")}</td> */}
                                                    </tr>
                                                ))}
                                                {Array.isArray(notificationListData) && notificationListData?.length == 0 && (
                                                    <tr className="tr-shadow">
                                                        <td colSpan="3" className="text-center">
                                                            No Data Found
                                                        </td>
                                                    </tr>
                                                )}
                                            </tbody>
                                        </table>
                                    </div>
                                    {Array.isArray(notificationListData) && notificationListData?.length > 0 &&
                                        <div className="d-flex justify-content-between align-items-center mt-4">
                                            <PaginationComponent onChangeHandle={onChangeHandle} pageCount={totalCount} pageNumber={pageNumber} setpage={setPageNumber} />
                                        </div>}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                {isOpenDeclineModal && <DeclinePopup
                    title={`Are you sure you want to ${itemObj?.is_active === true ? "Disable" : "Enable"} the ${itemObj?.name}?`}
                    onClose={() => onCloseModel()}
                    onSuccess={() => activeNotification()}
                />}
            </div>
        </React.Fragment>
    )
}

export default Notification