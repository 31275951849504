import { faEye, faEyeSlash } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useState } from "react";
import PasswordCheckPopover from "./PasswordCheckPopover";
import { validatePassword } from "../utils/Validation";
import { toast } from "react-toastify";

const ConfermationPopup = ({ onClose, onSuccess }) => {
  const [showOldPassword, setShowOldPassword] = useState(false);
  const [showPassword1, setShowPassword1] = useState(false);
  const [showPassword2, setShowPassword2] = useState(false);
  const [showPopoverForNewPass, setShowPopoverForNewPass] = useState(false)
  const [showPopoverForConfirmPass, setShowPopoverForConfirmPass] = useState(false)

  const [formValues, setFormValues] = useState({
    oldPassword: "",
    newPassword: "",
    confirmPassword: "",
  });

  const [formErrors, setFormErrors] = useState({
    oldPassword: "",
    newPassword: "",
    confirmPassword: "",
  });

  const handleInputChange = (event) => {
    const { name, value } = event.target;
    setFormValues({
      ...formValues,
      [name]: value,
    });
  };

  const validateForm = () => {
    let errors = {};

    if (!formValues?.oldPassword) {
      errors.oldPassword = "Old Password is required";
    }
    else if (!formValues?.newPassword) {
      errors.newPassword = "New Password is required";
    }
    else if (!validatePassword(formValues?.newPassword)) {
      errors.newPassword = "Strong Password is required";
    }
    else if (!formValues?.confirmPassword) {
      errors.confirmPassword = "Confirm Password is required";
    }
    else if (!validatePassword(formValues?.confirmPassword)) {
      errors.confirmPassword = "Strong Password is required";
    }
    else if (formValues?.newPassword !== formValues?.confirmPassword) {
      errors.confirmPassword = "";
      toast.warning("Passwords does not match")
    }

    setFormErrors(errors);

    return Object.keys(errors).length === 0;
  };
  const toggleOldPasswordVisibility = () => {
    setShowOldPassword(!showOldPassword);
  };
  const togglePasswordVisibility1 = () => {
    setShowPassword1(!showPassword1);
  };
  const togglePasswordVisibility2 = () => {
    setShowPassword2(!showPassword2);
  };
  return (
    <>
      <div className="modal d-block">
        <div className="modal-dialog">
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title">Change Password</h5>
              <button type="button" className="btn-close" onClick={onClose}></button>
            </div>
            <div className="modal-body">
              <div className="row g-3">
                <div className="col-12">
                  <label htmlFor="oldPassword" className="form-label">
                    Old Password
                  </label>
                  <div className="input-group">
                    <input
                      type={showOldPassword ? "text" : "password"}
                      className="form-control"
                      id="oldPassword"
                      name="oldPassword"
                      placeholder="Enter old password"
                      value={formValues?.oldPassword}
                      onChange={handleInputChange}
                    />
                    <div onClick={toggleOldPasswordVisibility} className="input-group-append">
                      <span className="input-group-text" style={{ padding: "11px" }}> 
                        <FontAwesomeIcon
                          icon={showOldPassword ? faEye : faEyeSlash}
                        />
                      </span>
                    </div>
                  </div>
                  <div className="text-danger">{formErrors?.oldPassword}</div>
                </div>
                <div className="col-12">
                  <label htmlFor="newPassword" className="form-label">
                    New Password
                  </label>
                  <div className="input-group">
                    {showPopoverForNewPass && <PasswordCheckPopover value={formValues?.newPassword} />}
                    <input
                      type={showPassword1 ? "text" : "password"}
                      className="form-control"
                      id="newPassword"
                      name="newPassword"
                      placeholder="Enter new password"
                      value={formValues?.newPassword}
                      onChange={handleInputChange}
                      onFocus={() => setShowPopoverForNewPass(true)}
                      onBlur={() => setShowPopoverForNewPass(false)}
                      autoComplete="off"
                    />
                    <div onClick={togglePasswordVisibility1} className="input-group-append">
                      <span className="input-group-text" style={{ padding: "11px" }}> 
                        <FontAwesomeIcon
                          icon={showPassword1 ? faEye : faEyeSlash}
                        />
                      </span>
                    </div>
                  </div>
                  <div className="text-danger">{formErrors?.newPassword}</div>
                </div>
                <div className="col-12">
                  <label htmlFor="confirmPassword" className="form-label">
                    Confirm Password
                  </label>
                  <div className="input-group">
                    {showPopoverForConfirmPass && <PasswordCheckPopover value={formValues?.confirmPassword} />}
                    <input
                      type={showPassword2 ? "text" : "password"}
                      className="form-control"
                      id="confirmPassword"
                      name="confirmPassword"
                      placeholder="Enter confirm password"
                      value={formValues?.confirmPassword}
                      onChange={handleInputChange}
                      onFocus={() => setShowPopoverForConfirmPass(true)}
                      onBlur={() => setShowPopoverForConfirmPass(false)}
                      autoComplete="off"
                    />
                    <div onClick={togglePasswordVisibility2} className="input-group-append">
                      <span className="input-group-text" style={{ padding: "11px" }}> 
                        <FontAwesomeIcon
                          icon={showPassword2 ? faEye : faEyeSlash}
                        />
                      </span>
                    </div>
                  </div>
                  <div className="text-danger">{formErrors?.confirmPassword}</div>
                </div>
              </div>
            </div>
            <div className="modal-footer">
              <button
                type="button"
                onClick={onClose}
                className="btn btn-secondary"
                data-bs-dismiss="modal"
              >
                Close
              </button>
              <button
                type="button"
                onClick={() => {
                  if (validateForm()) {
                    onSuccess(formValues);
                  }
                }}
                className="btn save_btn"
              >
                Save changes
              </button>
            </div>
          </div>
        </div>
      </div>
      <div className="modal-backdrop show"></div>
    </>
  );
};

export default ConfermationPopup;
