import React, { useEffect, useState } from "react"
import { useDispatch, useSelector } from "react-redux"
import { Actions } from "../redux/action"
import { getLoginData } from "../redux/reducer/loginReducer"
import { isValidEmail } from "../utils/Validation"
import MESSAGE from "../constant/String"
import { useNavigate } from "react-router-dom"
import { toast } from "react-toastify"
import loginImage from '../asset/img/login_img.svg';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEye, faEyeSlash } from '@fortawesome/free-solid-svg-icons';
import AppLoader from "../component/AppLoader"

const Login = () => {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const userData = useSelector(getLoginData)
    const [formDetails, setFormDetails] = useState({ emailId: "", password: "", captcha: "", captcha_id: "" });
    const [isLoading, setLoading] = useState(false);
    const [error, setError] = useState({ password: "", emailId: "", captcha: "" });
    const [showPassword, setShowPassword] = useState(false)
    const [captcha, setCaptcha] = useState({ id: null, image: null })
    useEffect(() => {
        if (userData?.token) {
            navigate('/dashboard')
        }
    }, [navigate, userData])
    useEffect(() => {
        generateCaptcha()
    }, [])
    const generateCaptcha = () => {
        const callback = (response) => {
            if (response?.response_code !== 0) {
                toast.error(response?.response_message)
            } else {
                setCaptcha({ id: response?.response?.captcha_id, image: response?.response?.captcha_image })
            }
        }
        dispatch(Actions.captcha('', callback))
    }
    const handleChange = (event) => {
        if (event.target.value === "") {
            setError({ ...error, emailId: MESSAGE?.PLEASE_ENTER_THE_EMAIL })
        } else if (!isValidEmail(event.target.value)) {
            setError({ ...error, emailId: MESSAGE?.PLEASE_ENTER_VALID_EMAIL })
        } else {
            setError({ ...error, emailId: '' })
        }
        setFormDetails({ ...formDetails, emailId: event.target.value })
    }

    const handlePasswordChange = (event) => {
        if (event.target.value === "") {
            setError({ ...error, password: MESSAGE?.PLEASE_ENTER_THE_PASSWORD })
        } else {
            setError({ ...error, password: '' })
        }
        setFormDetails({ ...formDetails, password: event.target.value })
    }

    const handleCaptchaChange = (event) => {
        if (event.target.value === "") {
            setError({ ...error, captcha: MESSAGE?.PLEASE_ENTER_CAPTCHA })
        } else {
            setError({ ...error, captcha: '' })
        }
        setFormDetails({ ...formDetails, captcha: event.target.value, captcha_id: captcha?.id })
    }

    const OnSubmit = () => {
        const { emailId, password } = formDetails
        const clientCredentialCallback = (response) => {
            if (response?.response_code !== 0) {
                setLoading(false)
                toast.error(response?.response_message)
            } else {
                dispatch(Actions.loginUser({ username: emailId, password: password, captcha: formDetails?.captcha, captcha_id: formDetails?.captcha_id, grant_type: process.env.REACT_APP_ADMIN_GRANT_TYPE, scope: process.env.REACT_APP_ADMIN_SCOPE }, callback))
            }
        }
        const callback = (response) => {
            setLoading(false)
            if (response?.response_code !== 0) {
                generateCaptcha()
                toast.error(response?.response_message)
            } else {
                navigate("/dashboard")
            }
        }
        if (emailId === "") {
            setError({ ...error, emailId: MESSAGE?.PLEASE_ENTER_THE_EMAIL })
        } else if (!isValidEmail(emailId)) {
            setError({ ...error, emailId: MESSAGE?.PLEASE_ENTER_VALID_EMAIL })
        } else if (formDetails?.password === "") {
            setError({ ...error, password: MESSAGE?.PLEASE_ENTER_THE_PASSWORD })
        } else if (formDetails?.captcha === "") {
            setError({ ...error, captcha: MESSAGE?.PLEASE_ENTER_CAPTCHA })
        } else {
            setLoading(true)
            dispatch(Actions.clientCredentials({ grant_type: process.env.REACT_APP_PUBLIC_GRANT_TYPE, scope: process.env.REACT_APP_PUBLIC_SCOPE }, clientCredentialCallback))
        }
    }
    const togglePasswordVisibility = () => {
        setShowPassword(!showPassword);
    }
    return (
        <React.Fragment>
            {isLoading && <AppLoader />}
            <div className="login_main_screen">
                <div className="card login_card">
                    <div className="row">
                        <div className="col-md-6 d-md-flex align-items-center d-none">
                            <img src={loginImage} className="img-fluid p-4" alt="Login" />
                        </div>
                        <div className="col-md-6 d-flex align-items-center">
                            {/* login start */}
                            <div className="p-lg-0 px-md-5 p-4">
                                <p className="fs-24 f-sbold mt-4">LOGIN</p>
                                {/* <p className="fs-14 f-reg">Please enter your email and password.</p> */}
                                <div className="email">
                                    <label className="form-label fs-16 f-sbold mb-1">Email</label>
                                    <input type="email" className="form-control select_options aisle_name" placeholder="Enter your email here" onChange={(e) => handleChange(e)} autoComplete="off" />
                                    {error?.emailId && <p className="text-danger">{error?.emailId}</p>}
                                </div>
                                <div className="password mt-4">
                                    <label className="form-label fs-16 f-sbold mb-1">Password</label>
                                    <div className="input-group">
                                        <input
                                            type={showPassword ? 'text' : 'password'}
                                            value={formDetails?.password}
                                            className="form-control select_options aisle_name small-input" // Added a custom class "small-input"
                                            placeholder="Enter your password here"
                                            autoComplete="off"
                                            onChange={(e) => handlePasswordChange(e)}
                                        />
                                        <div onClick={togglePasswordVisibility} className="input-group-append">
                                            <span className="input-group-text" style={{ padding: "11px" }}> 
                                                <FontAwesomeIcon
                                                    icon={showPassword ? faEye : faEyeSlash}
                                                />
                                            </span>
                                        </div>
                                    </div>
                                    {error?.password && <p className="text-danger">{error?.password}</p>}
                                    <span className="mt-4 pointer-hover" onClick={() => navigate('/forgetPassword')}>Forget Password ?</span>
                                </div>
                                <div className="captcha mt-4">
                                    <div className="captcha-container input-group">
                                        <img
                                            src={`data:image/png;base64,${captcha?.image}`} // Placeholder URL, replace with actual captcha image URL
                                            alt="Captcha"
                                            className="captcha-image form-control "
                                        />
                                        <div onClick={(e) => generateCaptcha()} className="regenerate-captcha-btn input-group-append">
                                            <span className="input-group-text" style={{ padding: "11px" }}>
                                                <svg xmlns="http://www.w3.org/2000/svg" height="18" width="18" viewBox="0 0 512 512">
                                                    <path d="M105.1 202.6c7.7-21.8 20.2-42.3 37.8-59.8c62.5-62.5 163.8-62.5 226.3 0L386.3 160H352c-17.7 0-32 14.3-32 32s14.3 32 32 32H463.5c0 0 0 0 0 0h.4c17.7 0 32-14.3 32-32V80c0-17.7-14.3-32-32-32s-32 14.3-32 32v35.2L414.4 97.6c-87.5-87.5-229.3-87.5-316.8 0C73.2 122 55.6 150.7 44.8 181.4c-5.9 16.7 2.9 34.9 19.5 40.8s34.9-2.9 40.8-19.5zM39 289.3c-5 1.5-9.8 4.2-13.7 8.2c-4 4-6.7 8.8-8.1 14c-.3 1.2-.6 2.5-.8 3.8c-.3 1.7-.4 3.4-.4 5.1V432c0 17.7 14.3 32 32 32s32-14.3 32-32V396.9l17.6 17.5 0 0c87.5 87.4 229.3 87.4 316.7 0c24.4-24.4 42.1-53.1 52.9-83.7c5.9-16.7-2.9-34.9-19.5-40.8s-34.9 2.9-40.8 19.5c-7.7 21.8-20.2 42.3-37.8 59.8c-62.5 62.5-163.8 62.5-226.3 0l-.1-.1L125.6 352H160c17.7 0 32-14.3 32-32s-14.3-32-32-32H48.4c-1.6 0-3.2 .1-4.8 .3s-3.1 .5-4.6 1z" /></svg>
                                            </span>
                                        </div>
                                    </div>
                                    <label className="form-label fs-16 f-sbold mt-3">Captcha</label>
                                    <input
                                        type="text"
                                        className="form-control select_options aisle_name "
                                        placeholder="Enter the captcha"
                                        onCut={(e) => e.preventDefault()}
                                        onCopy={(e) => e.preventDefault()}
                                        onPaste={(e) => e.preventDefault()}
                                        onDragOver={(e) => e.preventDefault()}
                                        onDrop={(e) => e.preventDefault()}
                                        onChange={(e) => handleCaptchaChange(e)}
                                    />
                                    {error?.captcha && <p className="text-danger">{error?.captcha}</p>}
                                </div>
                                <button className="btn login_btn mt-3 mb-3" type="button" onClick={() => OnSubmit()}>
                                    Login
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </React.Fragment>
    )
}

export default Login