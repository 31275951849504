import { Route, Routes } from 'react-router-dom';
import 'react-toastify/dist/ReactToastify.css';
import PrivateRoutes from './utils/PrivateRouter';
import Login from './container/Login';
import Dashboard from './container/Dashboard';
import { ToastContainer } from 'react-toastify';
import Store from './container/Store';
import Edge from './container/Edge';
import Camera from './container/Camera';
import Kiosk from './container/Kiosk';
import Category from './container/Category';
import AdminOnboard from './container/AdminOnboard';
import ForgetPassword from './container/ForgetPassword';
import ResetPassword from './container/ResetPassword';
import { getLoginData } from "../src/redux/reducer/loginReducer";
import { useSelector } from 'react-redux';
import AdminProduct from './container/AdminProduct';
import VendorProduct from './container/VendorProduct';
import Notification from './container/Notification';
import { StartSocketConnect } from './socket/SocketConnection';
import { useEffect } from 'react';

function App() {
  const userData = useSelector(getLoginData)
  console.log("tokenData", userData);
  useEffect(() => {
    if (userData?.socketId) {
      StartSocketConnect("", userData?.socketId)
    }
  }, [])
  return (
    <div className="App">
      <ToastContainer />
      <Routes>
        <Route path='/' element={<Login />} />
        <Route path='/forgetPassword' element={<ForgetPassword />} />
        <Route path='/resetPassword' element={<ResetPassword />} />
        <Route path='/' element={<PrivateRoutes />}>
          <Route path='/dashboard' element={<Dashboard />} />
          <Route path='/adminProduct' element={<AdminProduct />} />
          <Route path='/vendorProduct' element={<VendorProduct />} />
          <Route path='/store' element={<Store />} />
          <Route path='/edge' element={<Edge />} />
          <Route path='/camera' element={<Camera />} />
          <Route path='/kiosk' element={<Kiosk />} />
          <Route path='/notification' element={<Notification />} />
          {userData?.role_id === 1 && <Route path='/category' element={<Category />} />}
          {userData?.role_id !== 3 && <Route path='/adminOnboard' element={<AdminOnboard />} />}
        </Route>
      </Routes>
    </div>
  );
}

export default App;
