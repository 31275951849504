import axios from 'axios';
import { store } from '../redux/store';
import END_POINTS from '../service/EndPoint';
import MESSAGE from '../constant/String';
import { Actions } from '../redux/action';
import { requestEncrypt, responseDecrypt } from '../utils/Security';
let refreshTokenResponse = true;

const axiosInstance = axios.create({
    baseURL: END_POINTS.API_BASE_URL,
});

export const httpAuthHeader = () => {
    const tokendata = store?.getState()?.USER;
    const { token } = tokendata;
    return {
        'Authorization': `Token ${token}`,
        'Content-Type': 'application/json',
    }
}

export const clientCredentialsAuthHeader = () => {
    const tokendata = store?.getState()?.USER;
    const { client_credentials } = tokendata;
    return {
        'Authorization': `Bearer ${client_credentials}`,
        'Content-Type': 'application/json',
    }
}

export const basicAuthHeader = () => {
    return {
        'Authorization': `Basic ${process.env.REACT_APP_PUBLIC_TOKEN_KEY}`,
        'Content-Type': 'application/json',
    }
}
const checkForRedirect = () => {
    localStorage.clear();
    // setTimeout(() => {
    //     window.location.reload()
    // }, 0);
    window.location.href = '/'
}

//Check the api response and send the exact response to the callback
export const checkResponse = (response) => {
    if (typeof response?.data === 'string') {
        return response?.data;
    }
    if (response?.status === 200 && response?.data.response_code === 0) {
        return response?.data;
    } else if (response?.status === 200 && response?.data.response_code !== 0) {
        throw response?.data;
    } else if (response?.response?.status === 409) {
        throw response?.response?.data;
    } else if (response?.response?.status === 400) {
        throw response?.response?.data;
    } else if (response?.status !== 200) {
        throw MESSAGE?.SOMETHING_WENT_WORNG;
    }
    return response;
}

axiosInstance.interceptors.request.use(
    async (config) => {
        // Encrypt data in the request payload if needed
        if (config.data && process.env.REACT_APP_REQUEST_ENCRYPTION === "true") {
            config.data = requestEncrypt(JSON.stringify(config.data));
        }
        return config;
    },
    error => {
        Promise.reject(error)
    });

axiosInstance.interceptors.response.use((response) => {
    if (response?.data?.result && process.env.REACT_APP_RESPONSE_ENCRYPTION === "true") {
        let decryptedData = responseDecrypt(response?.data?.result); // Call the decryption function
        response.data = decryptedData; // Update the response data with the decrypted data
    }
    return response
}, async function (error) {
    if (error?.response?.data?.result && process.env.REACT_APP_REQUEST_ENCRYPTION === "true") {
        let decryptedData = responseDecrypt(error?.response?.data?.result); // Call the decryption function
        error.response.data = decryptedData; // Update the response data with the decrypted data
    }
    const originalRequest = error.config;
    if (error?.response?.status === 403 && !originalRequest._retry) {
        if (error?.response?.data?.detail) {
            originalRequest._retry = true;
            const tokendata = store?.getState()?.USER;
            const { refreshToken } = tokendata;
            let refreshTokenObj = {};
            if (refreshToken && refreshTokenResponse) {
                let url = END_POINTS.REFRESH_TOKEN
                let data = {
                    token: refreshToken
                }
                refreshTokenObj = await getRefreshToken(url, data);
                store.dispatch(Actions.loginUserSuccess(refreshTokenObj?.response))
                window.location.reload();
                originalRequest.headers.Authorization = `bearer ${refreshTokenObj?.access_token}`
                return axiosInstance(originalRequest);
            }
        }
        if (error?.response?.data?.response) {
            return checkForRedirect();
        }
    }
    if (error?.response?.status === 400) {
        return checkResponse(error);
    }
    return Promise.reject(error);
});

//Login
export const login = async (url, data) => {
    const config = {
        headers: clientCredentialsAuthHeader(),
    };
    try {
        const response = await axiosInstance.post(url, data, config);
        return response.data;
    } catch (error) {
        if (error.response.status === 401) {
            return checkResponse(error);
        }
        if (error.response.status === 409) {
            return checkResponse(error);
        }
        if (error.response.status === 400) {
            return checkResponse(error);
        }
        throw error;
    }
}

export const clientCredentialsLogin = async (url, data) => {
    const config = {
        headers: basicAuthHeader(),
    };
    try {
        const response = await axiosInstance.post(url, data, config);
        return response.data;
    } catch (error) {
        if (error.response.status === 401) {
            return checkResponse(error);
        }
        if (error.response.status === 409) {
            return checkResponse(error);
        }
        if (error.response.status === 400) {
            return checkResponse(error);
        }
        throw error;
    }
}

export const getRefreshToken = async (url, data) => {
    try {
        refreshTokenResponse = false
        const response = await axiosInstance.post(url, data);
        refreshTokenResponse = true
        return response.data;
    } catch (error) {
        if (error.response.status === 401) {
            return checkResponse(error);
        }
        if (error.response.status === 409) {
            return checkResponse(error);
        }
        if (error.response.status === 400) {
            return checkResponse(error);
        }
        throw error;
    }
}

// Admin Request
export const doGet = async (url, params) => {
    const config = {
        params,
        headers: httpAuthHeader(),
    };
    try {
        const response = await axiosInstance.get(url, config);
        return checkResponse(response);
    } catch (error) {
        if (error.response.status === 401) {
            return checkForRedirect();
        }
        throw error;
    }
}

export const doGetUnAuth = async (url, params) => {
    try {
        const response = await axiosInstance.get(url, params);
        return checkResponse(response);
    } catch (error) {
        if (error.response.status === 401) {
            return checkResponse(error);
        }
        if (error.response.status === 409) {
            return checkResponse(error);
        }
        if (error.response.status === 400) {
            return checkResponse(error);
        }
        throw error;
    }
}

export const doPost = async (url, data) => {
    const config = {
        headers: httpAuthHeader()
    };
    try {
        const response = await axiosInstance.post(url, data, config);
        return checkResponse(response);
    } catch (error) {
        if (error.response.status === 401) {
            return checkForRedirect();
        }
        if (error.response.status === 409) {
            return checkResponse(error);
        }
        if (error.response.status === 400) {
            return checkResponse(error);
        }
        throw error;
    }
}

export const doPostUnAuth = async (url, data) => {

    try {
        const response = await axiosInstance.post(url, data);
        return checkResponse(response);
    } catch (error) {
        if (error.response.status === 401) {
            return checkForRedirect();
        }
        if (error.response.status === 409) {
            return checkResponse(error);
        }
        if (error.response.status === 400) {
            return checkResponse(error);
        }
        throw error;
    }
}