import CryptoJS from 'crypto-js';

const serializedKey = process.env.REACT_APP_ENCRYPTION_KEY;
const serializedIV = process.env.REACT_APP_ENCRYPTION_IV;

const key = CryptoJS.enc.Base64.parse(serializedKey);
const iv = CryptoJS.enc.Base64.parse(serializedIV);

export const requestEncrypt = (raw_data) => {
    const cipher = CryptoJS.AES.encrypt(raw_data, key, { iv: iv }).toString();
    return cipher;
}

export const responseDecrypt = (encryptedData) => {
    let decrypted = CryptoJS.AES.decrypt(encryptedData, key, { iv: iv }).toString(CryptoJS.enc.Utf8);
    decrypted = JSON.parse(decrypted)
    return decrypted;
}
